<template>
  <div>
    <Card>
      <template #header>Masraf Kayıtları (Toplam : ₺0.00 )</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addMasraf = true"
              >Yeni</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Masraf Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
      <template v-slot:item.is_it_paid="{ item }">
        <p v-if="item == 1" class="text-dark">Evet</p>
        <p v-else="item == 0" class="text-dark">Hayır</p>
      </template>
    </List>
    <b-modal v-model="addMasraf" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Yeni Masraf</h4>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="">Masraf Adı </label>
            <textarea name="" id="" rows="1" class="form-control" v-model="cost_name"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Tutar </label>
            <textarea name="" id="" rows="1" class="form-control" v-model="amount"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Açıklama </label>
            <textarea name="" id="" rows="4" class="form-control" v-model="explanation"></textarea>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <b-form-checkbox name="check-button" switch v-model="is_it_paid">
              Ödendi mi?
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Ödendiği Tarih</label>
            <input type="date" class="form-control" v-model="paid_date" />
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addMasraf = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="save" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation 
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
    modalid="masraf" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "systemUsers",
  data() {
    return {
      addMasraf: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Masraf Adı", value: "explanation" },
        { text: "Tutar", value: "amount" },
        { text: "Ödendi mi?", value: "is_it_paid" },
        { text: "Ödeme Yapıldığı Tarih", value: "paid_date" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",

      cost_name: "",
      amount: "",
      explanation: "",
      is_it_paid: "",
      paid_date: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    save() {
      let data = {
        cost_name: this.cost_name,
        amount: this.amount,
        explanation: this.explanation,
        is_it_paid: this.is_it_paid,
        paid_date: moment(this.paid_date).format("DD/MM/YYYY"),
        user_token: this.myUser,
        property_id: this.$route.params.id
      };
      this.$ApiService
      .post("property/cost",data)
      .then((res) => {
        this.$generateNotification(this, "success","Masraf Eklenmiştir.");
        this.getList(),
        this.addMasraf = false
      })
      .catch((err) => {
        console.log(err)
        this.$generateNotification(this,"error","Masraf Eklenemedi.")
      })
    },
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/cost?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&property_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          console.log(res.response.data);
          this.items = res.response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(item) {
      this.$bvModal.show("modal-deleteOperationmasraf");
      this.deleteOperationUrl = `property/cost`;
      this.deleteIds = `?user_token=${this.myUser}&cost_id=${item._id.$oid}`;
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
